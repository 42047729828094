import React from 'react';
import { Button, Result } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
const App = () => {
    const location = useLocation();
    const data = location.state;
    const navigate = useNavigate();

    const goHome = async () => {
        navigate('/')
    }


    return (
        <>
          {data ? (
            <Result
              status="success"
              title="Successfully Submitted Your Inquiries"
              subTitle={
                " We will get back to you within 2 business days."
              }
              extra={[
                <Button type="primary" key="console" onClick={goHome}>
                  Go Home
                </Button>,
              ]}
            />
          ) : (
            <Result
            status="warning"
            title="There are some problems with your operation."
            extra={
              <Button type="primary" key="console" onClick={goHome}>
                Go Console
              </Button>
            }
          />
          )}
        </>
      );
      

};
export default App;
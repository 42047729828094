import React from 'react';
import { Button, Result } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
const App = () => {
  const navigate = useNavigate();

  const goHome = async () => {
      navigate('/')
  }

  return (
    <Result
    title="Something is wrong with the payment"
    subTitle="Don't worry, your money is still in your bank. You can go back to home page and retry.
    If you think it is an error on our side, please contact us: info.animeartisan@gmail.com"
    extra={
      <Button type="primary" key="console" onClick={goHome}>
        Go Home
      </Button>
    }
  />
  )
};

export default App;
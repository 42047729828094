import { GlobalStyle } from "../globalStyles";
import { lazy, Suspense } from "react";
import Header from "../components/Header";

const Home = lazy(() => import("./Home"));

const Footer = lazy(() => import("../components/Footer/index"));
const ScrollToTop = lazy(() => import("../components/ScrollToTop/index"));

function App() {
  return (
    <>
      <Suspense fallback={null}>
        <GlobalStyle />
        <ScrollToTop />
        <Header />
        <Home />
        <Footer />
      </Suspense>
    </>
  );
}

export default App;

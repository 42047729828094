import React from 'react';

import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Main from './Pages/MainPage'
import PaymentSuccess from "./Pages/PaymentSuccess"
import Unknow from "./Pages/404"
import Fail from "./Pages/PaymentFail"
import MessageSuccess from "./Pages/ContactSuccess"
import Auth from "./Pages/Login"
import TermsOfService from './Pages/Term';
import PrivacyPolicy from './Pages/Privacy';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path='/auth' element={<Auth />}/>
        <Route path="/messagesuccess" element={<MessageSuccess />}/>
        <Route path="/paymentsuccess" element={<PaymentSuccess />}/>
        <Route path="/paymentfailure" element={<Fail />}/>
        <Route path="/terms-of-service" element={<TermsOfService />}/>
        <Route path="/privacy" element={<PrivacyPolicy />}/>
        <Route path='*' element={<Unknow/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

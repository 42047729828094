import React, { useState, useEffect } from 'react';
import { Button, Result } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import global_config from '../global';
const App = () => {

  const [session, setSession] = useState({});

  const location = useLocation();
  const data = location.state;
  const sessionId = location.search.replace('?session_id=', '');

  const navigate = useNavigate();

  const goHome = async () => {
      navigate('/')
  }

  useEffect(() => {
    async function fetchSession() {
      setSession(
        await fetch(global_config.root_url+'/checkout-session?sessionId=' + sessionId).then((res) =>
          res.json()
        )
      );
    }
    fetchSession();
  }, [sessionId]);

  return (
    <Result
    status="success"
    title="Successfully Purchased Your Personal Anime Artisan!"
    subTitle={"tier: "+{session}}
    extra={[
      <Button type="primary" key="console" onClick={goHome}>
        Go Home
      </Button>,
    ]}
  />
  )
};

export default App;
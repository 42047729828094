import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function PrivacyPolicy() {
  return (
    <Box sx={{ width: '90%', maxWidth: 800, padding: 3 }}>
      <Typography variant="h5" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" gutterBottom>
        Last updated [Insert Date]
      </Typography>

      <Typography variant="body1" gutterBottom>
        This privacy notice for Anime Artisan ("we," "us," or "our") describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
      </Typography>
      <Typography variant="body1" component="ul" gutterBottom>
        <li>Visit our website at [YourWebsiteURL], or any website of ours that links to this privacy notice.</li>
        <li>Engage with us in other related ways, including any sales, marketing, or events.</li>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our services. If you still have any questions or concerns, please contact us at [Your Contact Email Address].
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Personal Data We Collect
      </Typography>
      <Typography variant="h6" gutterBottom>
        Personal information you disclose to us
      </Typography>
      <Typography variant="body1" gutterBottom>
        We collect information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
      </Typography>
      <Typography variant="body1" gutterBottom>
        The personal information we collect may include the following:
      </Typography>
      <Typography variant="body1" component="ul" gutterBottom>
        <li>Email addresses</li>
        <li>Usernames</li>
        <li>Passwords</li>
      </Typography>
      <Typography variant="body1" gutterBottom>
        We do not process sensitive information.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Personal Data We Obtain From Third Parties
      </Typography>
      <Typography variant="body1" gutterBottom>
        Single-Sign Ons – If you sign up to our website Anime Artisan through Google, you authorize us to collect your authentication information, such as your username and encrypted access credentials, and other personal data available through the third-party application account, including the following personal data:
      </Typography>
      <Typography variant="body1" component="ul" gutterBottom>
        <li>
          Google – You can sign up and log in to Anime Artisan using your Google account. When you sign up using Google, Google will ask your permission to share certain information from your Google account with Anime Artisan. This includes your Google email address and avatar (optional). This information is collected by Google and is provided to us under the terms of Google’s privacy policy. You can regulate the information that we receive from Google using your Google activity controls. If you signed up using Google by mistake, you can delete your account from the Account page within your Settings at any time, or you can contact us by emailing us at [Your Contact Email Address].
        </li>
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. How We Use Your Personal Data
      </Typography>
      <Typography variant="body1" gutterBottom>
        We use your personal data to:
      </Typography>
      <Typography variant="body1" component="ul" gutterBottom>
        <li>
          Provide, Improve, and Develop the Platform - We process your personal data primarily for the adequate performance of our website. We may also process your personal data in light of our legitimate interest in improving the Website and giving our users the best experience. We do this to:
        </li>
        <ul>
          <li>Operate and maintain your Anime Artisan account, and to provide you with access to and use of the Platform. Your email address and password are used to identify you when you log into the Platform.</li>
          <li>Identify you as the creator of the images that you upload, the comments that you post and/or the other contributions that you make to the Website.</li>
          <li>Respond to you about any comment or enquiry you have submitted.</li>
        </ul>
        <li>
          Maintain a Safe and Secure Environment - We also process your personal data in light of our legitimate interest in improving the security of the Platform (including the security of your personal data) in order to give our users the best experience.
        </li>
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. How We Share Your Personal Data
      </Typography>
      <Typography variant="body1" gutterBottom>
        We do not sell your personal data to external parties.
      </Typography>
      <Typography variant="body1" component="ul" gutterBottom>
        <li>
          Through Your Profile – Any personal data that you choose to disclose in your public profile on Anime Artisan will be accessible by other users of the website, who may view your profile information, view, or comment on any of your public images.
        </li>
        <li>
          Third-Party Applications – If you choose to connect your Anime Artisan account to other apps or social networks such as Facebook and Twitter, you may be sharing certain personal data with these apps or networks. Materials that you share with social media networks will appear on your timeline (or similar functions) as well as in feeds that may be viewed by the public or by certain followers, subject to the terms and conditions of such social media networks.
        </li>
        <li>
          Compliance with Laws – We may disclose your personal data to courts, law enforcement agencies, and governmental authorities (i) to comply with our legal obligations, (ii) to comply with legal process and to respond to claims asserted against Anime Artisan, (iii) to respond to verified requests relating to a criminal investigation or alleged or suspected illegal activity or any other activity that may expose us, you, or any other of our users to legal liability, (iv) to enforce and administer our Terms of Use and other agreements with users, or (v) to protect the rights, property, or personal safety of Anime Artisan, its employees, and members of the public.
        </li>
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Information Security
      </Typography>
      <Typography variant="body1" gutterBottom>
        Anime Artisan maintains appropriate technical, administrative, and physical safeguards that are designed to prevent unauthorized access, use, or disclosure of personal data we collect or store and to ensure a level of security appropriate to the risk of varying likelihood and severity for the rights and freedoms of natural persons that may be involved with our processing operations. We regularly monitor our systems for possible vulnerabilities and attacks. However, it is not possible to guarantee the security of personal data transmitted over the Internet. You use the website and provide us with personal data at your own discretion and risk.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Contact Information
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you have questions or complaints about this Privacy Policy or our practices, please contact us by email at [Your Contact Email Address].
      </Typography>
    </Box>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function TermsOfService() {
  return (
    <Box sx={{ width: '90%', maxWidth: 800, padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="body1" gutterBottom>
        Last updated August 17, 2024
      </Typography>

      <Typography variant="h5" gutterBottom>
        1. Terms
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.1 This document constitutes the Terms of Service between end-users of the Anime Artisan website and the Anime Artisan software application. "Anime Artisan" refers to the website, software application, and its provided service(s) for the remainder of this document.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.2 These terms must be adhered to by all users of the Anime Artisan image generation service and are agreed upon during the use of the service.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.3 If you do not agree to these terms, you must not use the Anime Artisan service nor download the software application.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.4 If you have any questions about these terms, please email us at [Your Contact Email Address].
      </Typography>

      <Typography variant="h5" gutterBottom>
        2. Accounts and Provided Services
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.1 An account may be required to access certain functionalities of Anime Artisan, including image generation and other premium features.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.2 You must be 18 years or older and capable of entering into a legal contract in order to use Anime Artisan.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.3 You may not share an account with any other person or entity, nor should you transfer your account ownership to another person or entity. You must keep your account information secure.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.4 You are responsible for all actions performed under your account and must ensure that they do not violate these terms.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.5 Your account may be terminated at any time for violations of these terms.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.6 If your account is terminated, there is no guarantee of future availability of your data, nor a refund for any purchases or subscriptions you may have made.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.7 Anime Artisan will attempt to ensure its services have high reliability, but no guarantee of availability or uptime is made. Anime Artisan may be integrated with, or interact with, third-party applications and devices. Use of such third-party applications and devices may be subject to additional terms from the third party, and Anime Artisan does not guarantee compatibility.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.8 Anime Artisan is not liable for any liabilities or damages arising from the use of its services, including images generated using our service. If you believe your usage of Anime Artisan is not in your best interests, you must cease using it and uninstall any related software.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.9 Anime Artisan reserves the right to change these Terms of Service at any time and will attempt to notify you of any changes.
      </Typography>

      <Typography variant="h5" gutterBottom>
        3. Account Purchases and Subscriptions
      </Typography>
      <Typography variant="body1" gutterBottom>
        3.1 Account purchases and subscriptions may award users with credits ("Credits"), which enable access to premium functionalities ("Membership Services") provided only to users with an active subscription.
      </Typography>
      <Typography variant="body1" gutterBottom>
        3.2 Credits may only be used for generating images on Anime Artisan.
      </Typography>
      <Typography variant="body1" gutterBottom>
        3.3 Credits are not redeemable for cash or any other goods or services.
      </Typography>
      <Typography variant="body1" gutterBottom>
        3.4 Credits may not be transferred to another user.
      </Typography>
      <Typography variant="body1" gutterBottom>
        3.5 All purchases are non-refundable. You may terminate your subscription at any time, but your subscription will not be renewed starting from the next billing period. Your access to Membership Services will continue until the expiration of your subscription.
      </Typography>
      <Typography variant="body1" gutterBottom>
        3.6 Previous credit balances will be carried forward in your account at the end of each subscription period or after the termination of your subscription.
      </Typography>
      <Typography variant="body1" gutterBottom>
        3.7 If a subscription is not renewed on its renewal date, Anime Artisan reserves the right to terminate your access to Membership Services.
      </Typography>

      <Typography variant="h5" gutterBottom>
        4. Generated Images
      </Typography>
      <Typography variant="body1" gutterBottom>
        4.1 No claims on the ownership or copyright of AI-generated images are made by Anime Artisan. All created content may be freely used by you.
      </Typography>
      <Typography variant="body1" gutterBottom>
        4.2 AI model output may sometimes be unpredictable and is not guaranteed to match the input prompt or be of any specific quality.
      </Typography>
      <Typography variant="body1" gutterBottom>
        4.3 Do not attempt to generate content that is illegal in your legal jurisdiction or within your country or district of residence.
      </Typography>
      <Typography variant="body1" gutterBottom>
        4.4 Do not access our generation service via any means other than the official Anime Artisan website or software application. For API access, please contact us at [Your Contact Email Address].
      </Typography>
      <Typography variant="body1" gutterBottom>
        4.5 Do not use generated content as a substitute for any licensed profession, including but not limited to legal, financial, or medical advice.
      </Typography>

      <Typography variant="h5" gutterBottom>
        5. Shared Content
      </Typography>
      <Typography variant="body1" gutterBottom>
        5.1 You are solely responsible for the content you post or make available on Anime Artisan, and any actions arising from your use of the service.
      </Typography>
      <Typography variant="body1" gutterBottom>
        5.2 You represent and warrant that you have ownership or rights to the content you post on Anime Artisan.
      </Typography>
      <Typography variant="body1" gutterBottom>
        5.3 Your content must not be misleading, unlawful, or violate any of these terms, applicable laws, or infringe on any rights of others. Anime Artisan may remove content at any time at its discretion.
      </Typography>
      <Typography variant="body1" gutterBottom>
        5.4 By posting content on Anime Artisan, you grant us a worldwide, royalty-free, non-exclusive license to display and publish such content as needed to provide the services.
      </Typography>
      <Typography variant="body1" gutterBottom>
        5.5 By uploading a file, image, or other content, you represent and warrant that doing so does not violate any rights of others and that you have the right to upload the material.
      </Typography>
      <Typography variant="body1" gutterBottom>
        5.6 We do not claim ownership of your uploaded or published content. You retain full ownership of all your uploaded content and any associated intellectual property rights. We are not liable for any statements or representations in your content.
      </Typography>

      <Typography variant="h5" gutterBottom>
        6. Use of Content
      </Typography>
      <Typography variant="body1" gutterBottom>
        6.1 By downloading or copying user-generated content (UGC) from Anime Artisan, you agree that you do not claim any rights to it.
      </Typography>
      <Typography variant="body1" gutterBottom>
        6.2 Your use of UGC is at your own risk. Anime Artisan makes no warranties of non-infringement, and you agree to indemnify and hold Anime Artisan harmless from any copyright infringement claims.
      </Typography>
      <Typography variant="body1" gutterBottom>
        6.3 When content contains a license notice (such as an open-source license), such content should remain under that license when further accessed, distributed, or used.
      </Typography>

      <Typography variant="h5" gutterBottom>
        7. User Data and Data Security
      </Typography>
      <Typography variant="body1" gutterBottom>
        7.1 Anime Artisan stores basic user information during your use of our service.
      </Typography>
      <Typography variant="body1" gutterBottom>
        7.2 For more information on what data is stored and your rights regarding that data, please refer to our Privacy Policy.
      </Typography>
      <Typography variant="body1" gutterBottom>
        7.3 Your usage of Anime Artisan constitutes agreement to this privacy policy and these terms.
      </Typography>

      <Typography variant="h5" gutterBottom>
        8. Restrictions of Conduct and Content
      </Typography>
      <Typography variant="body1" gutterBottom>
        8.1 In accessing and using Anime Artisan, you agree to abide by the following rules:
      </Typography>
      <Typography variant="body1" gutterBottom>
        a. You will not modify, translate, adapt, or reformat Anime Artisan.
      </Typography>
      <Typography variant="body1" gutterBottom>
        b. You will not reverse-engineer, decompile, disassemble, or attempt to discover the source code or structure of Anime Artisan.
      </Typography>
      <Typography variant="body1" gutterBottom>
        c. You will not interfere with or circumvent any security features of Anime Artisan.
      </Typography>
      <Typography variant="body1" gutterBottom>
        d. You will not use Anime Artisan to gain unauthorized access to any data, systems, or networks.
      </Typography>
      <Typography variant="body1" gutterBottom>
        e. You will not use Anime Artisan in a way that could damage, disable, overburden, or impair the service or interfere with other users' use of it.
      </Typography>
      <Typography variant="body1" gutterBottom>
        f. You will not use Anime Artisan in any way that may expose us or others to liability or damages.
      </Typography>
      <Typography variant="body1" gutterBottom>
        g. You will comply with all applicable laws in your use of Anime Artisan.
      </Typography>
      <Typography variant="body1" gutterBottom>
        h. You will properly label any content that is graphic, explicit, or otherwise objectionable.
      </Typography>
      <Typography variant="body1" gutterBottom>
        8.2 You shall not upload or share content that:
      </Typography>
      <Typography variant="body1" gutterBottom>
        a. Is unlawful, harmful, defamatory, or otherwise objectionable.
      </Typography>
      <Typography variant="body1" gutterBottom>
        b. Encourages criminal offenses, violates rights, or creates liability.
      </Typography>
      <Typography variant="body1" gutterBottom>
        c. Infringes on intellectual property rights.
      </Typography>
      <Typography variant="body1" gutterBottom>
        d. Contains unsolicited promotions or advertising.
      </Typography>
      <Typography variant="body1" gutterBottom>
        e. Includes private information of others without consent.
      </Typography>
      <Typography variant="body1" gutterBottom>
        f. Contains viruses or other harmful files.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Anime Artisan reserves the right to remove any content or ban accounts that violate these provisions.
      </Typography>

      <Typography variant="h5" gutterBottom>
        9. Liability
      </Typography>
      <Typography variant="body1" gutterBottom>
        9.1 Anime Artisan is provided on an "as-is" and "as-available" basis. We make no representations or warranties, express or implied, including warranties of merchantability, fitness for a particular purpose, and non-infringement.
      </Typography>
      <Typography variant="body1" gutterBottom>
        9.2 We do not warrant that the service will be error-free, secure, or uninterrupted, or that it will meet your requirements.
      </Typography>
      <Typography variant="body1" gutterBottom>
        9.3 We are not liable for any damages, including loss of profit, revenue, or business, arising from your use of Anime Artisan.
      </Typography>

      <Typography variant="h5" gutterBottom>
        10. Third-Party Rights
      </Typography>
      <Typography variant="body1" gutterBottom>
        10.1 If you have downloaded any of our mobile applications from a third-party app store, these terms are between you and Anime Artisan, not with the app store provider.
      </Typography>
      <Typography variant="body1" gutterBottom>
        10.2 The app store provider has no obligation to furnish any maintenance and support services with respect to Anime Artisan.
      </Typography>
      <Typography variant="body1" gutterBottom>
        10.3 The app store provider is not responsible for any claims, including those related to product liability, non-conformance to legal requirements, or intellectual property infringement.
      </Typography>

      <Typography variant="h5" gutterBottom>
        11. Contact Us
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you have any questions regarding the use of Anime Artisan or this TOS, please contact us at: [Your Contact Email Address].
      </Typography>

      <Typography variant="h5" gutterBottom>
        12. Governing Law
      </Typography>
      <Typography variant="body1" gutterBottom>
        These Terms of Service and any separate agreements whereby we provide you with services shall be governed by and construed in accordance with the laws of the region where the service is provided.
      </Typography>
    </Box>
  );
}

import React from 'react';
import { Button, Result } from 'antd';
import global_config from '../global';
const App = () => {
    const Login = async () => {
        window.location.href = global_config.root_url + '/login';
    }

    return (
        <Result
        status="warning"
        title="Please log in first"
        extra={
        <Button type="primary" key="console" onClick={Login}>
            Log in
        </Button>
        }
    />
    )

};
export default App;